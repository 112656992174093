import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsx mdx */

export const _frontmatter = {
  "path": "/developer/sass-for-css",
  "date": "2014-03-14",
  "title": "SASS FOR CSS",
  "author": "admin",
  "tags": ["development", "css", "sass"],
  "featuredImage": "feature.jpg",
  "excerpt": "Sass is a scripting langauage that is interpreted into CSS. Sass stands for Syntatically Awesome Stylesheets.  Sass consists of two syntaxes. The original syntax, called “the indented syntax”, uses a syntax similar to Haml. It uses indentation to separate code blocks and newline characters to separate rules."
};

const makeShortcode = name => function MDXDefaultShortcode(props) {
  console.warn("Component " + name + " was not imported, exported, or provided by MDXProvider as global scope");
  return <div {...props} />;
};

const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <p>{`The newer syntax, `}<strong parentName="p">{`SCSS`}</strong>{`, uses block formatting like that of CSS. It uses braces to denote code blocks and semicolons to separate lines within a block. The indented syntax and SCSS files are traditionally given the extensions `}<inlineCode parentName="p">{`.sass`}</inlineCode>{` and `}<inlineCode parentName="p">{`.scss`}</inlineCode>{` respectively.`}</p>
    <p>{`Let's start by `}<a parentName="p" {...{
        "href": "http://sass-lang.com/install"
      }}>{`installing`}</a>{` Sass first. `}</p>
    <h2>{`Installing Sass`}</h2>
    <p>{`ApplicationsThere are a good many applications that will get you up and running with Sass in a few minutes for Mac, Windows, and Linux. You can download most of the applications for free but a few of them are paid apps (and totally worth it).`}</p>
    <ul>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://incident57.com/codekit/"
        }}>{`CodeKit`}</a>{` (Paid)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://compass.handlino.com/"
        }}>{`Compass.app`}</a>{` (Paid, Open Source)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://hammerformac.com/"
        }}>{`Hammer`}</a>{` (Paid)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://koala-app.com/"
        }}>{`Koala`}</a>{` (Open Source)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://livereload.com/"
        }}>{`LiveReload`}</a>{` (Paid, Open Source)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://mixture.io/"
        }}>{`Mixture`}</a>{` (Paid)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://alphapixels.com/prepros/"
        }}>{`Prepros`}</a>{` (Paid, Open Source)`}</li>
      <li parentName="ul"><a parentName="li" {...{
          "href": "http://mhs.github.io/scout-app/"
        }}>{`Scout`}</a>{` (Open Source)
`}</li>
    </ul>
    <h3>{`Command line`}</h3>
    <p><strong parentName="p">{`Linux`}</strong></p>
    <p>{`If you're using a distribution of Linux, you'll need to install Ruby first. You can install Ruby through the apt package manager, rbenv, or rvm.`}</p>
    <p><strong parentName="p">{`Windows`}</strong></p>
    <p>{`Before you start using Sass you will need to install Ruby. The fastest way to get Ruby on your Windows computer is to use Ruby Installer. It's a single-click installer that will get everything set up for you super fast.
The installer will also install a Ruby command line powershell application that will let you use the Ruby libraries.`}</p>
    <p><strong parentName="p">{`Mac`}</strong></p>
    <p>{`If you prefer the command line over an application then getting Sass set up is a fairly quick process. Sass has a Ruby dependency but if you're using a Mac, congratulations, Ruby comes pre-installed.`}</p>
    <p>{`Here's the quickest way we've found to start using Sass by using the command line:`}</p>
    <ul>
      <li parentName="ul">{`Open your Terminal or Command Prompt. On the Mac the Terminal.app comes installed by default. It's located in your "Utilities" folder. On Windows, run `}<inlineCode parentName="li">{`cmd`}</inlineCode>{`.`}</li>
      <li parentName="ul">{`Install Sass. Ruby uses Gems to manage its various packages of code like Sass. In your open terminal window type:`}<inlineCode parentName="li">{`gem install sass`}</inlineCode>{`
This will install Sass and any dependencies for you. It's pretty magical. If you get an error message then it's likely you will need to use the `}<inlineCode parentName="li">{`sudo`}</inlineCode>{` command to install the Sass gem. It would look like:`}<inlineCode parentName="li">{`sudo gem install sass`}</inlineCode></li>
      <li parentName="ul">{`Double-check. You should now have Sass installed, but it never hurts to double-check. In your terminal application you can type: `}<inlineCode parentName="li">{`sass -v`}</inlineCode>{`
It should return `}<inlineCode parentName="li">{`Sass 3.3.2 (Maptastic Maple)`}</inlineCode>{`. Congratulations! You've successfully installed Sass.`}</li>
    </ul>
    <h2>{`Pre-processing`}</h2>
    <p>{`CSS on its own can lead to stylesheets getting larger, more complex, and harder to maintain. This is where a preprocessor can help. Sass lets you use features that don't exist in CSS yet like variables, nesting, mixins, inheritance and other nifty goodies that make writing CSS fun again.
Once you start tinkering with Sass, it will take your preprocessed Sass file and save it out as a normal CSS file that you can use in your web site.`}</p>
    <h2>{`Variables`}</h2>
    <p>{`Think of variables as a way to store information that you want to reuse throughout your stylesheet. You can store things like colors, font stacks, or any CSS value you think you'll want to reuse. Sass uses the `}<inlineCode parentName="p">{`$`}</inlineCode>{` symbol to make something a variable. `}</p>
    <p>{`Here's an example:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`$font-stack:    Helvetica, sans-serif;
$primary-color: #333;

body {
  font: 100% $font-stack;
  color: $primary-color;
}
`}</code></pre>
    <p>{`When the Sass is processed, it takes the variables we define for the `}<inlineCode parentName="p">{`$font-stack`}</inlineCode>{` and `}<inlineCode parentName="p">{`$primary-color`}</inlineCode>{` and outputs normal CSS with our variable values placed in the CSS. This can be extremely powerful when working with brand colors and keeping them consistent throughout the site.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`body {
  font: 100% Helvetica, sans-serif;
  color: #333;
}
`}</code></pre>
    <h2>{`Nesting`}</h2>
    <p>{`When you write HTML you've probably noticed that it has a fairly clear nested, visual hierarchy. CSS, on the other hand, isn't. Sass will let you nest your CSS selectors in a way that follows the same visual hierarchy of your HTML.
Here's an example of some typical styles for a site's navigation:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`nav {
    ul { margin: 0; padding: 0; list-style: none; } 
    li { display: inline-block; } 
    a { display: block; padding: 6px 12px; text-decoration: none; } 
}
`}</code></pre>
    <p>{`You'll notice that the `}<inlineCode parentName="p">{`ul`}</inlineCode>{`, `}<inlineCode parentName="p">{`li`}</inlineCode>{`, and `}<inlineCode parentName="p">{`a`}</inlineCode>{` selectors are nested inside the `}<inlineCode parentName="p">{`nav`}</inlineCode>{` selector.
This is a great way to organize your CSS and make it more readable.
When you generate the CSS you'll get something like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`nav ul {
  margin: 0;
  padding: 0;
  list-style: none;
}

nav li {
  display: inline-block;
}

nav a {
  display: block;
  padding: 6px 12px;
  text-decoration: none;
}
`}</code></pre>
    <h2>{`Partials`}</h2>
    <p>{`You can create partial Sass files that contain little snippets of CSS that you can include in other Sass files. This is a great way to modularize your CSS and help keep things easier to maintain. A partial is simply a Sass file named with a leading underscore. You might name it something like `}<inlineCode parentName="p">{`_partial.scss`}</inlineCode>{`. The underscore lets Sass know that the file is only a partial file and that it should be generated into a CSS file. Sass partials are used with the `}<inlineCode parentName="p">{`@import`}</inlineCode>{`directive.`}</p>
    <h2>{`Import`}</h2>
    <p>{`CSS has an import option that lets you split your CSS into smaller, more maintainable portions. The only drawback is that each time you use `}<inlineCode parentName="p">{`@import`}</inlineCode>{`in CSS it creates another HTTP request. Sass builds on top of the current CSS`}<inlineCode parentName="p">{`@import`}</inlineCode>{` but instead of requiring an HTTP request, Sass will take the file that you want to import and combine it with the file you're importing into so you can serve a single CSS file to the web browser.`}</p>
    <p>{`Let's say you have a couple of Sass files, `}<inlineCode parentName="p">{`reset.scss`}</inlineCode>{` and `}<inlineCode parentName="p">{`base.scss`}</inlineCode>{`. We want to import `}<inlineCode parentName="p">{`reset.scss`}</inlineCode>{` into `}<inlineCode parentName="p">{`base.scss`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`/* _reset.scss */

html,
body,
ul,
ol {
  margin:  0;
  padding: 0;
}

/* base.scss */

@import 'reset';

body {
  font-size: 100% Helvetica, sans-serif;
  background-color: #efefef;
}
`}</code></pre>
    <p>{`Notice we're using `}<inlineCode parentName="p">{`@import 'reset';`}</inlineCode>{` in the `}<inlineCode parentName="p">{`base.scss`}</inlineCode>{` file. When you import a file you don't need to include the file extension `}<inlineCode parentName="p">{`.scss`}</inlineCode>{` Sass is smart and will figure it out for you. `}</p>
    <p>{`When you generate the CSS you'll get:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`html, body, ul, ol {
  margin: 0;
  padding: 0;
}

body {
  background-color: #efefef;
  font-size: 100% Helvetica, sans-serif;
}
`}</code></pre>
    <h2>{`Mixins`}</h2>
    <p>{`Some things in CSS are a bit tedious to write, especially with CSS3 and the many vendor prefixes that exist. A mixin lets you make groups of CSS declarations that you want to reuse throughout your site. You can even pass in values to make your mixin more flexible. A good use of a mixin is for vendor prefixes. Here's an example for `}<inlineCode parentName="p">{`border-radius`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`@mixin border-radius($radius) {
  -webkit-border-radius: $radius;
     -moz-border-radius: $radius;
      -ms-border-radius: $radius;
       -o-border-radius: $radius;
          border-radius: $radius;
}

.box { @include border-radius(10px); }
`}</code></pre>
    <p>{`To create a mixin you use the `}<inlineCode parentName="p">{`@mixin`}</inlineCode>{` directive and give it a name. We've named our mixin `}<inlineCode parentName="p">{`border-radius`}</inlineCode>{`.
We're also using the variable `}<inlineCode parentName="p">{`$radius`}</inlineCode>{` inside the parentheses so we can pass in a radius of whatever we want.
After you create your mixin, you can then use it as a CSS declaration starting with`}<inlineCode parentName="p">{`@include`}</inlineCode>{` followed by the name of the mixin.
When your CSS is generated it'll look like this:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.box {
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  -ms-border-radius: 10px;
  -o-border-radius: 10px;
  border-radius: 10px;
}
`}</code></pre>
    <h2>{`Extend/Inheritance`}</h2>
    <p>{`This is one of the most useful features of Sass. Using `}<inlineCode parentName="p">{`@extend`}</inlineCode>{` lets you share a set of CSS properties from one selector to another. It helps keep your Sass for CSS very DRY. In our example, we're going to create a simple series of messaging for errors, warnings and successes.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.message {
  border: 1px solid #ccc;
  padding: 10px;
  color: #333;
}

.success {
  @extend .message;
  border-color: green;
}

.error {
  @extend .message;
  border-color: red;
}

.warning {
  @extend .message;
  border-color: yellow;
}
`}</code></pre>
    <p>{`What the above code does is allow you to take the CSS properties in `}<inlineCode parentName="p">{`.message`}</inlineCode>{`and apply them to `}<inlineCode parentName="p">{`.success`}</inlineCode>{`, `}<inlineCode parentName="p">{`.error`}</inlineCode>{`, & `}<inlineCode parentName="p">{`.warning`}</inlineCode>{`. The magic happens with the generated CSS, and this helps you avoid having to write multiple class names on HTML elements. This is what it looks like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.message, .success, .error, .warning {
  border: 1px solid #cccccc;
  padding: 10px;
  color: #333;
}
.success {
  border-color: green;
}

.error {
  border-color: red;
}

.warning {
  border-color: yellow;
}
`}</code></pre>
    <h2>{`Operators`}</h2>
    <p>{`Doing math in your CSS is very helpful. Sass has a handful of standard math operators like `}<inlineCode parentName="p">{`+`}</inlineCode>{`, `}<inlineCode parentName="p">{`-`}</inlineCode>{`, `}<inlineCode parentName="p">{`*`}</inlineCode>{`, `}<inlineCode parentName="p">{`/`}</inlineCode>{`, and `}<inlineCode parentName="p">{`%`}</inlineCode>{`.
In our example we're going to do some simple math to calculate widths for an `}<inlineCode parentName="p">{`aside`}</inlineCode>{` & `}<inlineCode parentName="p">{`article`}</inlineCode>{`.`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.container { width: 100%; }

article[role="main"] {
  float: left;
  width: 600px / 960px * 100%;
}

aside[role="complimentary"] {
  float: right;
  width: 300px / 960px * 100%;
}
`}</code></pre>
    <p>{`We've created a very simple fluid grid, based on 960px. Operations in Sass let us do something like take pixel values and convert them to percentages without much hassle. `}</p>
    <p>{`The generated CSS will look like:`}</p>
    <pre><code parentName="pre" {...{
        "className": "language-css"
      }}>{`.container {
  width: 100%;
}

article[role="main"] {
  float: left;
  width: 62.5%;
}

aside[role="complimentary"] {
  float: right;
  width: 31.25%;
}
`}</code></pre>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      